import React, { useState, useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import Container from '../components/atoms/Container';
import CollectionDetailModal from '../components/features/Modals/CollectionDetailModal';
import useLogoLounge from '../hooks/useLogoLounge';
import useModal from '../hooks/useModal';
import { ILogo } from '../types/logos.types';
import { searchLogos } from '../services/logo';
import { useSearchParams } from 'react-router-dom';
import Logo from '../components/atoms/Logo/Logo';
import SearchChips from '../components/molecules/SearchChips';
import Skeleton from '../components/atoms/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import usePrevious from '../hooks/usePrevious';
import useToast from '../hooks/useToast';
import { SKELETON_ITEMS } from '../utils/dummyData';

const Search = () => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = searchParams.toString();
  const prevParams = usePrevious(currentParams);

  const { setIsSearchOpen } = useLogoLounge();
  const { closeModal, openModal, isModalOpen } = useModal();
  const page_size = 30;
  const [count, setCount] = useState(0);
  const [logos, setLogos] = useState<ILogo[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSortChange = (newSort: string) => {
    const div = document.querySelector('#root-outer-div');
    div?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const newSearchParams = searchParams;
    newSearchParams.set('sort', newSort);
    setSearchParams(newSearchParams);
    //NOTE: changing the search parameters triggers the refreshLogos in the use effect below
  };

  const handlePageChange = () => {
    const currentPage = parseInt(searchParams.get('page') || '1', 10);
    const newPage = currentPage + 1;

    searchParams.set('page', newPage.toString());

    setSearchParams(searchParams);
  };

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_liked: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    } else if (action === 'save') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_collected: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    }
  };

  const refreshLogos = () => {
    document.title = `So many ${searchParams.has('keyword') ? searchParams.get('keyword') : ''} logos - LogoLounge - Work`;
    setIsLoading(true);
    const page = parseInt(searchParams.get('page') || '1');
    if (page === 1) {
      setLogos([]);
    }

    searchLogos({
      page,
      page_size: page_size,
      search_text: searchParams.get('keyword') || '',
      filter_category: searchParams.get('class') || '',
      filter_type: searchParams.get('type') || '',
      search_client: searchParams.get('client') || '',
      search_designer: searchParams.get('designer') || '',
      search_industry: searchParams.get('industry') || '',
      filter_country: searchParams.get('country') || '',
      sort: searchParams.get('sort') || 'recent',
    })
      .then(data => {
        if (data) {
          setLogos(prevLogos => (page === 1 ? data.data : [...prevLogos, ...data.data]));
          setCount(data.count);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error searching logos.',
          });
        }
        setIsLoading(false);
        if (searchParams.has('logo_id')) {
          openModal();
        }
      })
      .catch(error => {
        setIsLoading(false);
        toast.addToast({
          type: 'error',
          message: 'Error searching logos.',
        });
      });
  };

  useEffect(() => {
    if (
      (prevParams !== currentParams &&
        !searchParams.has('logo_id') &&
        !prevParams?.includes('logo_id')) ||
      (prevParams !== currentParams && prevParams?.includes('logo_id'))
    ) {
      refreshLogos();
    } else if (searchParams.has('logo_id')) {
      openModal();
    }
  }, [prevParams, currentParams, searchParams]);

  useEffect(() => {
    refreshLogos();
  }, []);

  return (
    <Layout className="search">
      <div className="search_outer">
        <main id="content">
          <Container>
            <div className="results_header">
              <SearchChips onClick={() => setIsSearchOpen(true)} />
              <div className="sort_outer">
                <select
                  id="sort"
                  title="Sort"
                  onChange={event => handleSortChange(event.target.value)}
                  value={searchParams.get('sort') || 'recent'}
                >
                  <option value="recent">Most Recent</option>
                  <option value="popular">Most Popular</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            {!isLoading &&
              <div className="results_count_top">{count.toLocaleString()} logos</div>
            }
            <div className="logos_outer">
              <ul>
                <InfiniteScroll
                  next={() => {
                    !isLoading && handlePageChange();
                  }}
                  hasMore={logos.length < count}
                  loader=""
                  dataLength={count}
                >
                  {logos &&
                    logos.map((logo: any, index: number) => (
                      <li key={`logo-${index}`}>
                        <Logo
                          logo={logo}
                          section="fresh"
                          index={index}
                          actionCallback={actionCallback}
                          bookAward={logo.book_id ? `book-${logo.book_id}` : ''}
                          masterBookAward={
                            logo.master_book_id
                              ? `master-book-${logo.master_book_id}`
                              : ''
                          }
                        />
                      </li>
                    ))}
                  {isLoading &&
                    Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                      <li key={`logo-skeleton-${index}`} className="loading">
                        <Skeleton className="square" />
                      </li>
                    ))}
                </InfiniteScroll>
              </ul>
            </div>

            {isModalOpen && (
              <CollectionDetailModal
                closeDetailModal={closeModal}
                logos={logos}
                actionCallback={actionCallback}
              />
            )}
          </Container>
        </main>
      </div>
    </Layout>
  );
};

export default Search;
