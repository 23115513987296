import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import { getCountries } from '../utils/functions';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { joinSchema } from '../schemas/join.schema';
import { IJoin } from '../types/join.types';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import axios from '../api';
import useAuth from '../hooks/useAuth';
import useToast from '../hooks/useToast';

const countries = getCountries();

const Join = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const toast = useToast();
  const [terms, setTerms] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
    control,
  } = useForm<IJoin>({ resolver: yupResolver(joinSchema) });

  useEffect(() => {
    document.title = 'Join LogoLounge - Logo Design Inspiration & Competition - Work';

    if (isAuthenticated) navigate('/checkout');
  }, []);

  const handleContinue = async () => {
    const isPasswordValid = await trigger('password');
    if (isPasswordValid && terms) return setShowForm(true);
    return setShowForm(false);
  };

  const handleChange = (
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Replace all non-digit characters with an empty string
    const cleanedValue = event.target.value.replace(/\D/g, '');

    // Format the phone number as "(XXX) XXX-XXXX"
    const formattedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    event.target.value = formattedValue;
    onChange(event);
  };

  const handleJoin = (userData: IJoin) => {
    axios
      .post('/api/register/validate', { user: userData, isNew: true })
      .then(response => {
        if (response.status === 200) {
          navigate('/checkout', { state: { userData } });
        } else {
          toast.addToast({
            type: 'error',
            message: 'Invalid User Info: ' + response.statusText,
          });
        }
      })
      .catch(error => {
        console.error('Invalid User Info: ', error?.response?.data?.error);
        toast.addToast({
          type: 'error',
          message: 'Invalid User Info: ' + error?.response.statusText,
        });
      });
  };

  return (
    <Layout className="join has_right_background">
      <main id="content">
        <figure></figure>

        <article>
          <div className="content_outer">
            <h1>Join the fun.</h1>
            <form className="form-join" onSubmit={handleSubmit(handleJoin)}>
              <input type="hidden" name="step" value="1" />

              <fieldset>
                <legend>New Account</legend>

                <div className="form-group">
                  <label className="control-label">Username</label>
                  <input className="form-control" type="text" {...register('username')} />
                </div>

                <div className="form-group">
                  <label className="control-label">Password</label>
                  <div className="password-container">
                    <input
                      className="form-control input-password"
                      type={showPassword ? 'text' : 'password'}
                      maxLength={20}
                      {...register('password')}
                    />

                    <div
                      className="icon"
                      onClick={() => setShowPassword(prevShow => !prevShow)}
                    >
                      {showPassword ? <VscEye /> : <VscEyeClosed />}
                    </div>
                  </div>
                  <span className="error_message">{errors.password?.message}</span>
                </div>

                <div
                  className="form-group checkbox_outer"
                  onClick={e => {
                    e.stopPropagation();
                    setTerms(oldState => !oldState);
                  }}
                >
                  <input
                    type="checkbox"
                    id="terms"
                    value={'1'}
                    checked={terms}
                    required
                    readOnly={true}
                  />
                  <label>
                    I agree to the{' '}
                    <a href="/terms" target="_blank">
                      Terms and Conditions
                    </a>
                    .
                  </label>
                </div>
              </fieldset>

              <div className="button_outer">
                <p>
                  <a href="login">Already a member?</a>
                </p>

                <label className="button" onClick={handleContinue}>
                  Continue
                </label>
              </div>

              {showForm && (
                <>
                  <fieldset>
                    <legend>About You</legend>

                    <div className="form-group-wrap--name">
                      <div className="form-group">
                        <label className="control-label">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('first_name')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('last_name')}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Company</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register('company')}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        {...register('email')}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Phone</label>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="text"
                            //{...register('phone')}
                            {...field}
                            onChange={event => handleChange(field.onChange, event)}
                            pattern="\(\d{3}\) \d{3}-\d{4}"
                            placeholder="(123) 456-7890"
                            maxLength={14}
                          />
                        )}
                      />
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Your Whereabouts</legend>

                    <div className="form-group-wrap--street-suite">
                      <div className="form-group">
                        <label className="control-label">Street Address</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('address1')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Suite</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('address2')}
                        />
                      </div>
                    </div>

                    <div className="form-group-wrap--city-state-zip">
                      <div className="form-group">
                        <label className="control-label">City</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('city')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">State/Province</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('state')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Postal Code</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('zip')}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Country/Region</label>
                      <select className="form-control" {...register('country')}>
                        <option value="">Choose a country...</option>
                        {countries.map(country => (
                          <option key={country.value} value={country.value}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="button_outer">
                      <button type="submit">Create Account</button>
                    </div>
                  </fieldset>
                </>
              )}
            </form>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default Join;
