import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from '../components/molecules/Layout';
import Loading from '../components/molecules/Loading';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISubmitLogo } from '../types/submitLogo.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitLogoSchema } from '../schemas/submitLogo.schema';
import clsx from 'clsx';
import { generateLogoInfo, getLogos, submitLogo, updateLogo } from '../services/logo';
import useToast from '../hooks/useToast';
import { ILogo } from '../types/logos.types';
import { processFileUrl } from '../utils/image';
import DeleteLogo from '../components/features/pages/EditLogo/DeleteLogo';
import EditSkeleton from '../components/features/pages/EditLogo/EditSkeleton';
import { deleteValidation } from '../utils/functions';

const Submit = () => {
  const toast = useToast();
  useEffect(() => {
    document.title = 'Submit Your Logo - LogoLounge';
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const [logo, setLogo] = useState<ILogo>();

  // Parse the search query string
  const queryParams = new URLSearchParams(location.search);
  const logoId = Number(queryParams.get('logoId')) || undefined;
  const [moreCredits, setMoreCredits] = useState<boolean>(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [fileMessage, setFileMessage] = useState<string>('');
  // const [isLoadingAI, setIsLoadingAI] = useState(false);
  const fileBlobInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadingLogo, setUploadingLogo] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loadingLogoData, setLoadingLogoData] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const { setValue, handleSubmit, register, reset } = useForm<ISubmitLogo>({
    resolver: yupResolver(SubmitLogoSchema),
    context: { moreCredits },
  });

  const onSubmit = (values: ISubmitLogo) => {
    setUploadingLogo(true);
    if (!logoId) {
      submitLogo(values).then(response => {
        if (response) {
          navigate('/designers?tab=logos');
        } else {
          setUploadingLogo(false);
          toast.addToast({
            type: 'error',
            message: 'Error submitting logo.',
          });
        }
      });
    } else {
      const updateValues = {
        logo_id: logoId,
        illustrator: logo?.illustrator,
        photographer: logo?.photographer,
        keywords2: logo?.keywords2,
        class_name: logo?.class_name,
        assoc_creative_director: logo?.assoc_creative_director,
        art_director: values.art_director,
        logotype: values.logotype,
        creative_director: values.creative_director,
        industry: values.industry,
        client: values.client,
        tags: values.tags,
        description: values.description,
        logo: values.title,
        keywords: values.tags?.replace(/\s*,\s*/g, ','),
      };
      updateLogo(updateValues)
        .then(response => {
          setUploadingLogo(false);
          if (response) {
            navigate('/designers?tab=logos');
          } else {
            toast.addToast({
              type: 'error',
              message: 'Error updating logo.',
            });
          }
        })
        .catch(() => setUploadingLogo(false));
    }
  };

  const processFileSelect = useCallback(
    (file: File) => {
      setFileMessage('');
      if (file.size > 2000000) {
        setFileMessage('File size must be < 2MB');
        return;
      }
      if (fileBlobInputRef.current !== null) {
        // setIsLoadingAI(true);
        fileBlobInputRef.current.value = '';
        const reader = new FileReader();
        reader.onload = function (e) {
          if (e.target && e.target.result && fileBlobInputRef.current) {
            const fileDataURL = e.target.result as string;
            var image = new Image();
            image.onload = function () {
              const width = image.naturalWidth;
              const height = image.naturalHeight;
              if (width !== height) {
                setFileMessage('File must be a square.');
              } else if (width > 900 || height > 900) {
                setFileMessage('File must be 900 x 900 or less.');
              } else {
                setFileUrl(fileDataURL);
                setValue('filename', file.name);
                setValue(
                  'file_base64',
                  fileDataURL.substring(fileDataURL.indexOf(',') + 1)
                );
                setValue('title', file.name.substring(0, file.name.lastIndexOf('.')));
              }
            };
            image.src = fileDataURL;
            fileBlobInputRef.current.value = fileDataURL;
            // generateLogoInfo(fileDataURL).then(response => {
            //   if (response) {
            //     setValue('tags', response.keywords);
            //     setValue('description', response.description);
            //   }
            //   setIsLoadingAI(false);
            // });
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [setValue]
  );

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      processFileSelect(files[0]);
    }
  };

  const handleFileDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (logoId) return;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        setIsDragOver(false);
        processFileSelect(files[0]);
      }
    },
    [processFileSelect]
  );

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (logoId) return;

    setIsDragOver(true);
  }, []);

  const loadLogoDetails = () => {
    setLoadingLogoData(true);
    getLogos({ logo_id: logoId }).then(async logo => {
      if (logo) {
        const { base64, filename } = await processFileUrl(logo.url);
        setLogo(logo);
        setFileUrl(logo.url);
        reset({
          filename: filename,
          file_base64: base64,
          title: logo.logo || '',
          description: logo.description || '',
          art_director: logo.art_director || '',
          creative_director: logo.creative_director || '',
          client: logo.client || '',
          industry: logo.industry || '',
          logotype: logo.logotype || 'Symbol',
          tags: logo.keywords,
        });
        setCanDelete(deleteValidation(logo.upload_date));
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error loading logo details.',
        });
      }
      setLoadingLogoData(false);
    });
  };

  useEffect(() => {
    if (logoId) loadLogoDetails();
  }, [logoId]);

  return (
    <Layout className="submit">
      {uploadingLogo && <Loading />}
      {loadingLogoData ? (
        <EditSkeleton />
      ) : (
        <main id="content">
          <div className="content_outer wide">
            <h1>{logoId ? 'Edit ' : 'Submit '} Your Logo</h1>

            <form onSubmit={handleSubmit(onSubmit)} action="#" className="upload_form">
              <div className="image_group">
                <figure className="upload-preview">
                  <div
                    id="drop-bucket"
                    className={clsx(
                      'upload-bucket',
                      isDragOver && 'is-drag-over',
                      logoId && 'no-before'
                    )}
                    onDrop={handleFileDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={() => setIsDragOver(false)}
                  >
                    {fileUrl && <img src={fileUrl} alt="" />}
                  </div>
                </figure>

                {!fileUrl && (
                  <label htmlFor="upload-file-input">
                    {fileMessage && (
                      <>
                        <span style={{ color: 'red' }}>{fileMessage}</span>
                        <br />
                      </>
                    )}
                    <strong>Choose</strong>
                    <span>or drag and drop</span>
                  </label>
                )}

                <input
                  id="upload-file-input"
                  type="file"
                  name="file"
                  onChange={e => handleFileSelect(e.target.files)}
                />
                <input id="upload-file-blob" type="hidden" ref={fileBlobInputRef} />
              </div>

              <input
                type="checkbox"
                id="more_credits_toggle"
                checked={moreCredits}
                readOnly={true}
              />
              <div className="data_group">
                <fieldset>
                  <label className="required">Title</label>
                  <input type="text" id="upload-file-title" {...register('title')} />
                </fieldset>
                <fieldset>
                  <label>Description</label>
                  {/* {isLoadingAI && (
                    <p style={{ fontSize: '10px', color: 'black' }}>
                      Loading AI Suggestions...
                    </p>
                  )} */}
                  <textarea
                    {...register('description')}
                    // disabled={isLoadingAI}
                  />
                </fieldset>
                <fieldset className="has_more_credits_toggle">
                  <label
                    className="more_credits_toggle"
                    htmlFor="more_credits_toggle"
                    onClick={() => setMoreCredits(true)}
                  >
                    Add More Credits...
                  </label>
                </fieldset>

                <div className="more_credits">
                  <fieldset>
                    <label>Art Director</label>
                    <input type="text" {...register('art_director')} />
                  </fieldset>
                  <fieldset>
                    <label>Creative Director</label>
                    <input type="text" {...register('creative_director')} />
                  </fieldset>
                </div>

                <div className="group">
                  <fieldset>
                    <label>Client</label>
                    <input type="text" {...register('client')} />
                  </fieldset>
                  <fieldset>
                    <label>Industry</label>
                    <input type="text" {...register('industry')} />
                  </fieldset>
                </div>

                <fieldset>
                  <label className="control-label required">Type of Logo</label>
                  <div className="checkbox_outer">
                    <input
                      id="logo-checkbox-symbol"
                      type="radio"
                      value="Symbol"
                      {...register('logotype')}
                    />
                    <label htmlFor="logo-checkbox-symbol">Symbol</label>

                    <input
                      id="logo-checkbox-typographic"
                      type="radio"
                      value="Typographic"
                      {...register('logotype')}
                    />
                    <label htmlFor="logo-checkbox-typographic">Typographic</label>

                    <input
                      id="logo-checkbox-combo"
                      type="radio"
                      value="Combo"
                      {...register('logotype')}
                    />
                    <label htmlFor="logo-checkbox-combo">Combo</label>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Tags (comma-separated list)</label>
                  {/* {isLoadingAI && (
                    <p style={{ fontSize: '10px', color: 'black' }}>
                      Loading AI Suggestions...
                    </p>
                  )} */}
                  <textarea
                    placeholder=""
                    {...register('tags')}
                    // disabled={isLoadingAI}
                  ></textarea>
                </fieldset>

                <div className="container_button_outter">
                  {logoId && canDelete && (
                    <button type="button" onClick={() => setIsOpenModal(true)}>
                      Delete
                    </button>
                  )}
                  <button className="submit" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <DeleteLogo
              isOpenModal={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              logoId={logoId as number}
            />
          </div>
        </main>
      )}
    </Layout>
  );
};

export default Submit;
