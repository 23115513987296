import axios from '../api';
import { ISubmitLogo, IUpdateLogo } from '../types/submitLogo.types';

export const submitLogo = async (values: ISubmitLogo) => {
  try {
    const response = await axios.post('/api/logos', {
      logo: values.title,
      filename: values.filename,
      file_base64: values.file_base64,
      art_director: values.art_director,
      creative_director: values.creative_director,
      client: values.client,
      industry: values.industry,
      logotype: values.logotype,
      keywords: ',' + values.tags?.replace(/\s*,\s*/g, ','),
      description: values.description,
    });
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const updateLogo = async (values: IUpdateLogo) => {
  try {
    const response = await axios.put(`/api/logos`, {
      ...values,
    });
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const deleteLogo = async (logoId: number) => {
  try {
    const response = await axios.delete(`/api/logos`, {
      params: { logo_id: logoId },
    });
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    console.error('Failed to delete logo:', error);
    return error;
  }
};

export const getLogos = async ({
  logo_id,
  designerId,
  page,
  page_size,
}: {
  logo_id?: number;
  designerId?: number | string;
  page?: number;
  page_size?: number;
}) => {
  try {
    const response = await axios.get('/api/logos', {
      params: {
        logo_id,
        designer_id: designerId,
        page,
        page_size,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getLogosBySlug = async ({
  logo_id,
  slug,
  page,
  page_size,
}: {
  logo_id?: number;
  slug?: string;
  page?: number;
  page_size?: number;
}) => {
  try {
    const response = await axios.get('/api/logos', {
      params: {
        logo_id,
        folder_url: slug,
        page,
        page_size,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getFresh = async ({
  page,
  page_size,
}: {
  page?: number;
  page_size?: number;
}) => {
  try {
    const response = await axios.get('/api/logos/fresh', {
      params: {
        page,
        page_size,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getFollowing = async ({
  page,
  page_size,
}: {
  page?: number;
  page_size?: number;
}) => {
  try {
    const response = await axios.get('/api/logos/following', {
      params: {
        page,
        page_size,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getRelated = async ({ logo_id }: { logo_id: number }) => {
  try {
    const response = await axios.get('/api/logos/related', {
      params: {
        logo_id,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const searchLogos = async ({
  page,
  page_size,
  search_text,
  filter_category,
  filter_type,
  search_client,
  search_designer,
  search_industry,
  filter_country,
  sort,
}: {
  page: number;
  page_size: number;
  search_text?: string;
  filter_category?: string;
  filter_type?: string;
  search_client?: string;
  search_designer?: string;
  search_industry?: string;
  filter_country?: string;
  sort: string;
}) => {
  try {
    const response = await axios.get('/api/logos', {
      params: {
        page,
        page_size,
        search_text,
        filter_category,
        filter_type,
        search_client,
        search_designer,
        search_industry,
        filter_country,
        sort,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getLogoComments = async (logo_id: number) => {
  try {
    const response = await axios.get('/api/logos/comments', { params: { logo_id } });
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const addComment = async ({
  logo_id,
  comment,
}: {
  logo_id: number;
  comment: string;
}) => {
  try {
    const response = await axios.post('/api/logos/comments', {
      logo_id: logo_id,
      comment: comment,
    });
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const deleteComment = async ({ comment_id }: { comment_id: number }) => {
  try {
    const response = await axios.put('/api/logos/comments', {
      comment_id: comment_id,
    });
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const generateLogoInfo = async (url: string) => {
  try {
    const response = await axios.post('/api/logos/generate-info', {
      url: url,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};
