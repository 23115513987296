import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { INewCollection } from '../../../../types/collection.types';
import {
  createCollection,
  getCollections,
  saveToCollection,
  removeFromCollection,
} from '../../../../services/collections';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../molecules/Loading';
import useToast from '../../../../hooks/useToast';

interface ICollection {
  logoId: number;
  closeDetailModal: () => void;
  actionCallback: any;
}

interface ICollectionResponse {
  light_box_id: number;
  name: string;
  description: string;
  logo_urls_list: string;
  logo_ids_list: string;
  date_added: string;
  count: number;
}

const Collection: FC<ICollection> = ({ logoId, closeDetailModal, actionCallback }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    handleSubmit: handleSubmitNew,
    register: registerNew,
    setValue: setValueNew,
  } = useForm<INewCollection>({ defaultValues: { is_public: '1' } });

  const [isLoading, setIsLoading] = useState(true);
  const [userCollections, setUserCollections] = useState<ICollectionResponse[]>([]);
  const [collectionIdsContainingLogo, setCollectionIdsContainingLogo] = useState<
    string[]
  >([]);
  const [formState, setFormState] = useState<boolean[]>([]);

  const onSubmitSave = async (event: any) => {
    event.preventDefault();

    if(await handleFormState()){
      toast.addToast({
        type: 'success',
        message: 'Collections saved successfully.',
      });
      refreshCollections();
      actionCallback(logoId, 'save', { value: formState.includes(true) ? 1 : 0 });
      closeDetailModal();
    } else {
      toast.addToast({
        type: 'error',
        message: 'Error saving collections.',
      });
      closeDetailModal();
    }
  };

  async function handleFormState() {
    var success = true;
    await formState.forEach(async (value, index) => {
      const collectionID = userCollections[index].light_box_id;
      if (value && !collectionIdsContainingLogo.includes(collectionID.toString())) {
        // Checked and not already in collection
        if(!(await saveToCollection(collectionID, logoId))){
          success = false;
        }
      } else if (
        !value &&
        collectionIdsContainingLogo.includes(collectionID.toString())
      ) {
        // Unchecked and in collection
        if(!(await removeFromCollection(collectionID, logoId))){
          success = false;
        }
      }
    });
    return success;
  }

  const onSubmitNewCollection = (values: INewCollection) => {
    createCollection(values).then(response => {
      if(response){
        const collectionID = response.light_box_id;
        saveToCollection(collectionID, logoId).then(response => {
          if(response){
            navigate(`/collection/${collectionID}`);
          } else {
            closeDetailModal();
            toast.addToast({
              type: 'error',
              message: 'Error saving to collection.',
            });
          }
        }
        );
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error creating collection.',
        });
      }
    });
  };

  const refreshCollections = () => {
    setIsLoading(true);
    getCollections({}).then(response => {
      if(response){
        setUserCollections(response);
        const newCollectionsContainingLogo = response
          .filter((collection: ICollectionResponse) => {
            if (collection && collection.logo_ids_list) {
              const flatLogoIds = collection.logo_ids_list.split(',');
              if (logoId && flatLogoIds.includes(logoId.toString())) {
                return true;
              }
            }
            return false;
          })
          .map((collection: ICollectionResponse) => {
            return collection.light_box_id.toString();
          });
        setCollectionIdsContainingLogo(newCollectionsContainingLogo);
        setFormState(
          response.map((collection: ICollectionResponse) => {
            return newCollectionsContainingLogo.includes(
              collection.light_box_id.toString()
            );
          })
        );
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error getting collections.',
        });
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    refreshCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoId]);

  const checkHandler = (index: number) => {
    setFormState(
      formState.map((state, i) => {
        if (i === index) {
          return !state;
        }
        return state;
      })
    );
  };

  return (
    <>
      <form
        id="add-to-collection"
        onSubmit={onSubmitSave}
        data-action-value="add-to-collection"
      >
        {isLoading ? (
          <Loading />
        ) : (
          <section className="collections-container">
            {userCollections.map((collection, index) => {
              return (
                <div className="collection-box" key={`collection_${index}`}>
                  <input
                    id={`collection-${index}`}
                    className="hidden-input"
                    type="checkbox"
                    data-action="save-to-collection"
                    data-action-value=""
                    checked={formState[index] || false}
                    onChange={() => checkHandler(index)}
                  />
                  <article className="collection-item">
                    <div className="collection-item-logos">
                      {collection?.logo_urls_list &&
                        collection.logo_urls_list
                          .split(',')
                          .slice(0, 6)
                          .map((url, index) => {
                            return (
                              <div key={'logo-' + index} className="logo_item">
                                <div className="image_outer">
                                  <img src={url} alt="" />
                                </div>
                              </div>
                            );
                          })}

                      {collection?.logo_urls_list &&
                        collection.logo_urls_list.split(',').length < 6 &&
                        [...Array(6 - collection.logo_urls_list.split(',').length)].map(
                          (_, subIndex) => {
                            return (
                              <div className="logo_item" key={`logo-list_${subIndex}`}>
                                <div className="image_outer"></div>
                              </div>
                            );
                          }
                        )}
                    </div>
                    <div className="collection-item-info">
                      <h3 className="collection-item-title">
                        <a href="/">{collection?.name}</a>
                      </h3>
                      <div className="collection-item-meta">
                        <span data-role="count">{collection?.count} logos</span>
                      </div>
                    </div>

                    <label htmlFor={`collection-${index}`} className="save_toggle_button">
                      <span>Save</span>
                    </label>
                  </article>
                </div>
              );
            })}
          </section>
        )}
      </form>

      <form
        onSubmit={handleSubmitNew(onSubmitNewCollection)}
        id="new-collection-form" //method="post" */
      >
        <div className="form-group">
          <label className="control-label">Title</label>
          <input className="form-control" type="text" {...registerNew('name')} />
        </div>

        <div className="form-group">
          <label className="control-label">Description</label>
          <textarea className="form-control" {...registerNew('description')}></textarea>
        </div>

        <div className="form-group">
          <div className="checkbox_outer">
            <input
              id="logo-checkbox-public"
              type="radio"
              value="1"
              {...registerNew('is_public')}
              onChange={() => setValueNew('is_public', '1')}
            />
            <label className="checkbox" htmlFor="logo-checkbox-public" tabIndex={0}>
              <span className="control-label">Public</span>
            </label>

            <input
              id="logo-checkbox-private"
              type="radio"
              value="0"
              {...registerNew('is_public')}
              onChange={() => setValueNew('is_public', '0')}
            />
            <label className="checkbox" htmlFor="logo-checkbox-private" tabIndex={0}>
              <span className="control-label">Private</span>
            </label>
          </div>
        </div>
      </form>
    </>
  );
};

export default Collection;
